import React, {Component, useRef} from 'react';
import {HashRouter as Router, Switch, Route, Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {Toast} from 'primereact/toast';
import {Menubar} from 'primereact/menubar';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import Favicon from 'react-favicon';
import {Sidebar} from "primereact/sidebar";
import {BlockUI} from "primereact/blockui";
import * as Sentry from "@sentry/browser";
import {Dialog} from "primereact/dialog";

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';
import './assets/css/app.css';

import LoginPage from './pages/LoginPage';
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordSetPage from "./pages/PasswordSetPage";
import DashboardPage from './pages/DashboardPage';
import DashboardEntryfishPage from './pages/_DashboardPage_Entryfish';
import SubscriptionPage from './pages/SubscriptionPage';
import ReportDashboardPage from './pages/report/ReportDashboardPage';
import ReportDashboardEntryfishPage from './pages/report/_ReportDashboardPage_Entryfish';
import PartnerDashboardPage from "./pages/partner/PartnerDashboardPage";
import ProjectDashboardPage from "./pages/project/ProjectDashboardPage";
import EntryDashboardPage from "./pages/entry/EntryDashboardPage";
import FinanceDashboardPage from "./pages/finance/FinanceDashboardPage";
import HrDashboardPage from "./pages/hr/HrDashboardPage";
import _HrDashboardPage_Entryfish from "./pages/hr/_HrDashboardPage_Entryfish";
import PurchaseDashboardPage from "./pages/purchase/PurchaseDashboardPage";
import OrderDashboardPage from "./pages/order/OrderDashboardPage";
import InventoryDashboardPage from "./pages/inventory/InventoryDashboardPage";
import SettingsDashboardPage from "./pages/settings/SettingsDashboardPage";
import SafetyDashboardPage from "./pages/safety/SafetyDashboardPage";
import ProfileFormComponent from "./components/profile/ProfileFormComponent";
import ManufacturingDashboardPage from "./pages/manufacturing/ManufacturingDashboardPage";
import RegisterPage from "./pages/RegisterPage";

import UserLogoutService from './services/user/UserLogoutService';
import NetworkListService from "./services/network/NetworkListService";
import ModulesListService from "./services/modules/ModulesListService";
import EntrylocationByNetworkShowComponent from "./components/entrylocation/EntrylocationByNetworkShowComponent";
import EntrylocationByNetworkFormComponent from "./components/entrylocation/EntrylocationByNetworkFormComponent";
import EntrylogListService from "./services/entrylog/EntrylogListService";
import moment from "moment";
import EntrylogCountService from "./services/entrylog/EntrylogCountService";
import NetworkSubscriptionMessageService from "./services/network/NetworkSubscriptionMessageService";
import currencyFormatter from "currency-formatter";

import i18next from 'i18next';
import common_hu from "./translations/hu/common.json";
import common_hu_brw from "./translations/hu/common_brw.json";

if (process.env.REACT_APP_BRAND === 'lbuild') {
  require('./assets/css/lbuild.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'entryfish') {
  require('./assets/css/entryfish.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'brw') {
  require('./assets/css/brw.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'opuseu') {
  require('./assets/css/opuseu.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 't2d') {
  require('./assets/css/t2d.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'water2farm') {
  require('./assets/css/water2farm.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}

class App extends Component {

  constructor(props) {
    super(props);
    this.toastShow = this.toastShow.bind(this);

    window.App = this;

    this.state = {}

    this.menu = [];

    if(process.env.REACT_APP_BRAND === 'brw') {
      i18next.init({
        resources: {
          hu: {
            common: common_hu_brw
          }
        },
      });
    } else {
      i18next.init({
        resources: {
          hu: {
            common: common_hu
          }
        },
      });
    }
  };

  componentDidMount() {
    if (window.matchMedia("(prefers-color-scheme:dark)").matches === true) {
      document.getElementById('body').classList.add('dark-theme')
      document.getElementById('body').classList.add(process.env.REACT_APP_BRAND)
    }
    this.networkSelect()
    // if (window.innerWidth < 1680) {
    //   localStorage.setItem('scale', 'small')
    //   document.getElementById('app').classList.add('scale-small')
    // } else {
    //   document.getElementById('app').classList.add('scale-normal')
    // }
    localStorage.setItem('api_host', process.env.REACT_APP_API_HOST)
    setTimeout(function () {
      document.getElementById('root').style.display = "block"
    }, 500)
    setInterval(function () {
      if (localStorage.getItem('userToken') && process.env.REACT_APP_SUBSCRIPTION_MESSAGES === "enabled") {
        NetworkSubscriptionMessageService()
          .then(response => {
            window.App.setState({
              subscriptionMessages: response.data
            })
          })
          .catch(error => {
            // window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      }
    }, Number(process.env.REACT_APP_SUBSCRIPTION_GET_INTERVAL));
  }

  componentDidUpdate() {
    this.menu = []
    if (localStorage.getItem('module_partner')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_partner'),
          icon: 'pi pi-fw pi-briefcase',
          url: '/#/partner',
          className: 'partner'
        }
      )
    }
    if (localStorage.getItem('module_project')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_project'),
          icon: 'pi pi-fw pi-clone',
          url: '/#/project',
          className: 'project'
        }
      )
    }
    if (localStorage.getItem('module_finance')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_finance'),
          icon: 'pi pi-fw pi-wallet',
          url: '/#/finance',
          className: 'finance'
        }
      )
    }
    if (localStorage.getItem('module_purchase')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_purchase'),
          icon: 'pi pi-database',
          url: '/#/purchase',
          className: 'purchase'
        }
      )
    }
    if (localStorage.getItem('module_order')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_order'),
          icon: 'pi pi-fw pi-shopping-cart',
          url: '/#/order',
          className: 'order'
        }
      )
    }
    if (localStorage.getItem('module_inventory')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_inventory'),
          icon: 'pi pi-th-large',
          url: '/#/inventory',
          className: 'inventory'
        }
      )
    }
    if (localStorage.getItem('module_entry')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_entry'),
          icon: 'pi pi-fw pi-id-card',
          url: '/#/entry',
          className: 'entry'
        }
      )
    }
    if (localStorage.getItem('module_occupational_safety')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_occupational_safety'),
          icon: 'pi pi-fw pi-shield',
          url: '/#/safety',
          className: 'safety'
        }
      )
    }
    if (localStorage.getItem('module_hr')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_hr'),
          icon: 'pi pi-fw pi-users',
          url: '/#/hr',
          className: 'hr'
        }
      )
    }
    if (localStorage.getItem('module_maintenance')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_maintenance'),
          icon: 'pi pi-fw pi-tag',
          url: '/#/maintenance',
          className: 'maintenance'
        }
      )
    }
    if (localStorage.getItem('module_manufacturing')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_manufacturing'),
          icon: 'pi pi-fw pi-plus',
          url: '/#/manufacturing',
          className: 'manufacturing'
        }
      )
    }
    if (localStorage.getItem('module_settings')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_settings'),
          icon: 'pi pi-fw pi-cog',
          url: '/#/settings',
          className: 'settings'
        }
      )
    }
    if (localStorage.getItem('module_report')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_report'),
          icon: 'pi pi-fw pi-chart-line',
          url: '/#/report',
          className: 'report'
        }
      )
    }
    if (localStorage.getItem('module_support')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_support'),
          icon: 'pi pi-fw pi-question-circle',
          url: '/#/support',
          className: 'support'
        }
      )
    }
  }

  pageHeight = () => {
    //tabview sroll
    setTimeout(function () {
      if (document.getElementById('tabScroll')) {
        if (document.getElementById('tabScroll').scrollWidth > document.getElementById('tabScroll').parentElement.offsetWidth) {
          window.App.setState({tabScroll: true})
        } else {
          window.App.setState({tabScroll: false})
        }
      }
    }, 500)

    //page height
    if (window.innerWidth > 1200) {
      let headerHight = 52
      let windowHeight = window.innerHeight
      let pageHeight = windowHeight - headerHight
      if (document.getElementById("page")) {
        document.getElementById("page").getElementsByClassName("DashboardPage")[0].style.cssText
          = "height:" + pageHeight + "px !important";
      }
    }
    window.addEventListener('resize', function (event) {
      if (window.innerWidth > 1200) {
        let headerHight = 52
        let windowHeight = window.innerHeight
        let pageHeight = windowHeight - headerHight
        if (document.getElementById("page")) {
          document.getElementById("page").getElementsByClassName("DashboardPage")[0].style.cssText
            = "height:" + pageHeight + "px !important";
        }
      }
    })
  }

  networkSelect = () => {
    if (localStorage.getItem('userToken')) {
      NetworkListService()
        .then(response => {
          if (response.data && response.data[0]) {
            window.App.setState({
              networks: response.data
            })
            if (!localStorage.getItem('networkId') && !localStorage.getItem('networkName')) {
              localStorage.setItem('networkId', response.data[0].id)
              localStorage.setItem('networkName', response.data[0].name)
            }
          }
          ModulesListService()
            .then(response => {
              Object.keys(localStorage)
                .filter(key => key.startsWith("module_"))
                .forEach(key => localStorage
                  .removeItem((key)))
              Object.entries(response.data).map(function (item, index) {
                localStorage.setItem('module_' + item[1].code, item[1].name);
              })
              window.App.forceUpdate()
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          window.App.forceUpdate()
          if (localStorage.getItem('userToken') && process.env.REACT_APP_SUBSCRIPTION_MESSAGES === "enabled") {
            NetworkSubscriptionMessageService()
              .then(response => {
                window.App.setState({
                  subscriptionMessages: response.data
                })
              })
              .catch(error => {
                // window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    window.App.forceUpdate()
  }

  currencyFormatter = (currency, amount) => {
    let currencyFormat
    if (currency === 'HUF') {
      currencyFormat = {
        precision: 2,
        symbol: '',
        decimal: ',',
        thousand: ' ',
      }
    } else {
      currencyFormat = {
        precision: 2,
        symbol: '',
        decimal: '.',
        thousand: ' ',
      }
    }
    return (
      <>
        {currencyFormatter.format(
          amount, {
            precision: currencyFormat.precision,
            symbol: currencyFormat.symbol,
            decimal: currencyFormat.decimal,
            thousand: currencyFormat.thousand,
          }
        )}&nbsp;{currency}
      </>
    )
  }

  toastShow(severity, summary, detail, life, response) {
    // if(severity && severity === 'error' && process.env.NODE_ENV !== 'development') {
    //   let error
    //   if(detail) {
    //     if(detail.toString().includes('code 401') || detail.toString().includes('partnernek nincs kapcsolattartója')) {
    //       // do nothing
    //     } else {
    //       error = detail.toString() + " - " + window.location.href + " - " + localStorage.getItem('networkName')
    //       Sentry.captureException(error);
    //     }
    //   } else {
    //     error = '(no detail)' + " - " + window.location.href + " - " + localStorage.getItem('networkName')
    //     Sentry.captureException(error);
    //   }
    // }
    if (life === null || life === undefined || life < 3000 || !life) {
      life = 5000
    }
    if (response && response.data) {
      if (response.status === 400) {
        var elementInvalid = document.getElementsByClassName("p-invalid");
        var elementError = document.getElementsByClassName("p-error");
        while (elementInvalid.length > 0) {
          elementInvalid[0].classList.remove("p-invalid");
          elementError[0].remove();
        }
        const data = response.data
        let detail = ''
        let summary = 'A kijelölt mezők helyes kitöltése kötelező!'
        if (Object.values(response.data)[0]) {
          summary = Object.values(response.data)[0]
        }
        severity = "warn"
        for (const key in data) {
          if (document.getElementById(key)) {
            document.getElementById(key).classList.add("p-invalid")
            document.getElementById(key).parentElement.parentElement.insertAdjacentHTML("afterend", "<small class=\"p-error\">" + data[key] + "</small>")
            document.getElementById(key).addEventListener('click', function handleClick() {
              document.getElementById(key).classList.remove("p-invalid");
              if (document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error')) {
                document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error').remove();
              }
            });
          }
          //detail = detail + ' ' + data[key] // nem jön mindenhol értelmes üzenet!
        }
        if (summary = 'Nem található') {
          summary = 'A kijelölt mezők helyes kitöltése kötelező!'
          if (response.data && response.data.message) {
            detail = response.data.message
          }
        }
        window.App.toast.show({
          severity: severity,
          summary: summary,
          detail: detail,
          life: life
        });
      } else if (response.status === 401) {
        if (localStorage.getItem('userToken') == null) {
          detail = "Hibás adatok! A bejelentkezéshez valós emailcím és jelszó megadása szükséges."
          window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
        } else {
          detail = "Lejárt munkamenet miatt a rendszer kilépett."
          severity = "warn"
          summary = "A továbblépéshez be kell jelentkezni!"
          UserLogoutService()
          window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
        }
      } else if (response.status === 404) {
        summary = "Valami nem stimmel!"
        detail = response.data.message
        if (response.data && response.data.message && response.data.message.toString().length > 200) {
          detail = process.env.REACT_APP_DEVOPS_ERROR
        }
        severity = "warn"
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
      } else if (response.status === 403) {
        summary = "Jogosultság nem megfelelő!"
        severity = "warn"
        detail = 'Az adatok betöltése sikertelen.'
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
      } else if (response.status === 409 || response.status === 500) {
        summary = "Hoppá! Hiba történt!"
        detail = process.env.REACT_APP_DEVOPS_ERROR
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
      } else {
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
      }
    } else if (detail && detail.toString().includes("401") || detail && detail.toString().includes("Authentication")) {
      detail = "Lejárt munkamenet miatt a rendszer kilépett."
      severity = "warn"
      summary = "A továbblépéshez be kell jelentkezni!"
      UserLogoutService()
      window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
    } else {
      if (severity === 'error') {
        life = 15000
        detail = process.env.REACT_APP_DEVOPS_ERROR
      }
      window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
    }
  }

  urlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  render() {
    return (
      <Router>
        <div className={process.env.REACT_APP_BRAND} id="app">
          <Favicon url={require("." + process.env.REACT_APP_FAVICON_FILE)}/>
          <Toast ref={(el) => this.toast = el} position="bottom-right"/>
          {process.env.REACT_APP_ACCESS === 'disabled' ? (
            <>
              <div className="p-component p-p-3 p-text-center">
                <img src={require("." + process.env.REACT_APP_LOGO_FILE).default}
                     className="p-d-block p-my-5 p-mx-auto"/>
                <br/>
                <p className={"p-pb-0"}>
                  <strong>Karbantartás folyamatban!</strong>
                </p>
                <p className={"p-pb-3"}>
                  Pár perc türelmet kérünk és a rendszer ismét elérhető lesz.
                </p>
              </div>
            </>
          ) : (
            <>
              {localStorage.getItem('userToken') == null ? (
                <Switch>
                  <Route path="/" component={LoginPage} exact/>
                  <Route path="/login" component={LoginPage}/>
                  <Route path="/partner" component={LoginPage}/>
                  <Route path="/settings" component={LoginPage}/>
                  <Route path="/report" component={LoginPage}/>
                  <Route path="/finance" component={LoginPage}/>
                  <Route path="/project" component={LoginPage}/>
                  <Route path="/entry" component={LoginPage}/>
                  <Route path="/hr" component={LoginPage}/>
                  <Route path="/safety" component={LoginPage}/>
                  <Route path="/purchase" component={LoginPage}/>
                  <Route path="/order" component={LoginPage}/>
                  <Route path="/inventory" component={LoginPage}/>
                  <Route path="/manufacturing" component={LoginPage}/>
                  {process.env.REACT_APP_REGISTRATION == 'enabled' &&
                  <Route path="/registration" component={RegisterPage}/>
                  }
                  {process.env.REACT_APP_REGISTRATION == 'disabled' &&
                  <Route path="/registration" component={LoginPage}/>
                  }
                  <Route path="/password-reset" component={PasswordResetPage}/>
                  <Route path="/password-set" component={PasswordSetPage}/>
                </Switch>
              ) : (
                <>
                  {localStorage.getItem('scale') === 'small' &&
                  <style>
                    {"\
                  html{\
                    font-size:12px !important;\
                    transition: 0.2s !important;\
                  }\
                "}
                  </style>
                  }
                  {localStorage.getItem('scale') === 'normal' &&
                  <style>
                    {"\
                  html{\
                    font-size:16px !important;\
                    transition: 0.2s !important;\
                  }\
                "}
                  </style>
                  }
                  <BlockUI blocked={this.state.loading}>
                    {/*<div className={"subscriptionMessage"}>*/}
                    {/*  <div className={"p-text-center p-p-2 bg-" + "error" + "-dark text-light"}>*/}
                    {/*    Jelen időszakban szolgáltatói hibából adódóan email kézbesítési problémák léphetnek fel. A proléma megoldásán a szolgáltatóval közösen dolgozunk. A számla kiállítási folyamatot a hiba nem befolyásolja.*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {window.App.state.subscriptionMessages && window.App.state.subscriptionMessages[0] &&
                    window.App.state.subscriptionMessages.map((message, index) => {
                      if (!localStorage.getItem("subscriptionMessage_" + message.id)) {
                        return (
                          <div className={"subscriptionMessage"}
                               onClick={() => {
                                 let confirmMessage = 'A következő bejelentkezésig ne jelenjen meg ez az üzenet?'
                                 if (message.note) {
                                   confirmMessage = message.note
                                 }
                                 if (message.closable === true) {
                                   if (window.confirm(confirmMessage)) {
                                     localStorage.setItem("subscriptionMessage_" + message.id, message.message)
                                     window.App.forceUpdate()
                                   }
                                 } else {
                                   if (message.note) {
                                     alert(confirmMessage)
                                   }
                                   if(message.type === 'subscription_expired') {
                                     window.location.replace('/#/subscriptions');
                                     window.App.forceUpdate()
                                   }
                                 }
                               }}
                          >
                            <div className={"p-text-center p-p-2 bg-" + message.severity + "-dark text-light"}>
                              {message.message}
                              {message.closable === true &&
                              <i className={"pi pi-times"}></i>
                              }
                            </div>
                          </div>
                        )
                      }
                    })
                    }
                    {this.state.loading === true ? (
                      <div id="header" className={"p-menubar p-component p-py-0"}
                           style={{minHeight: '52px', position: 'relative'}}>
                        <i className="pi pi-spin pi-spinner p-mt-0 p-pt-0 p-ml-4 p-d-none p-d-lg-block"
                           style={{fontSize: '1.5em', position: 'absolute', left: '0', top: '14px'}}></i>
                        <i className="pi pi-spin pi-spinner p-mt-0 p-pt-0 p-ml-4 p-d-lg-none"
                           style={{fontSize: '1.5em', position: 'absolute', left: '0', top: '17px'}}></i>
                      </div>
                    ) : (
                      <div id="header">
                        <nav>
                          <>
                            <Menubar
                              style={{minHeight: '52px'}}
                              start={
                                <>
                                  <Link to={"/"}>
                                    <img src={require("." + process.env.REACT_APP_LOGO_FILE).default}
                                         width={"120"}/>
                                  </Link>
                                </>
                              }
                              model={this.menu}
                              className={"p-py-0"}
                              end={
                                <>
                                  <div className="p-inputgroup">
                                    {process.env.REACT_APP_BRAND === 'entryfish' &&
                                    <>
                                      {localStorage.getItem('roles').toString().includes('role_entry_location_update')
                                      || localStorage.getItem('roles').toString().includes('role_entry_location_description')
                                      || localStorage.getItem('roles').toString().includes('role_entry_location_subcontractor_invite') ? (
                                        <>
                                          <Button icon="pi pi-building"
                                                  type="button"
                                                  className={""}
                                                  tooltip={"Projekt adatlap"}
                                                  tooltipOptions={{position: 'bottom'}}
                                                  onClick={() => {
                                                    window.App.setState({
                                                      sidebarEntrylocationByNetworkShow: true
                                                    })
                                                  }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Button icon="pi pi-building"
                                                  type="button"
                                                  className={""}
                                                  tooltip={"Projekt adatlap"}
                                                  disabled
                                                  tooltipOptions={{position: 'bottom'}}
                                          />
                                        </>
                                      )}
                                    </>
                                    }
                                    {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
                                    <Button icon="pi pi-building"
                                            type="button"
                                            className={"p-button-warning"}
                                            tooltip={"Előfizetői fiók"}
                                            tooltipOptions={{position: 'bottom'}}
                                            onClick={() => {
                                              window.location.replace('/#/subscriptions');
                                              window.App.forceUpdate()
                                            }}
                                    />
                                    }
                                    {localStorage.getItem('networkId') &&
                                    <>
                                      <Dropdown options={window.App.state.networks}
                                                optionLabel="name"
                                                optionValue="id"
                                                id={"networkName"}
                                                emptyMessage={"Még nincs céged..."}
                                                onMouseDown={() => {
                                                  NetworkListService()
                                                    .then(response => {
                                                      if (response.data && response.data[0]) {
                                                        window.App.setState({
                                                          networks: response.data
                                                        })
                                                      }
                                                    })
                                                }}
                                                onChange={(e) => {
                                                  let network = window.App.state.networks.filter(item => item.id === e.value)
                                                  localStorage.setItem('networkId', e.value)
                                                  localStorage.setItem('networkName', network[0].name)
                                                  localStorage.removeItem('defaultCompanyId')
                                                  localStorage.removeItem('defaultCompanyName')
                                                  localStorage.removeItem('defaultPaymentMethodId')
                                                  localStorage.removeItem('defaultPaymentMethodName')
                                                  localStorage.removeItem('defaultReceiptGroupId')
                                                  localStorage.removeItem('defaultReceiptGroupName')
                                                  this.networkSelect();
                                                  window.App.forceUpdate()
                                                  window.App.setState({
                                                    loading: true
                                                  })
                                                  setTimeout(function () {
                                                    window.location.replace('/#/');
                                                    window.location.reload() // TODO
                                                  }, 2500)
                                                }}
                                                value={Number(localStorage.getItem('networkId'))}
                                      />
                                    </>
                                    }
                                    {localStorage.getItem('scale') === 'small' &&
                                    <Button icon="pi pi-search-plus"
                                            type="button"
                                            tooltip={"Betűméret növelése"}
                                            tooltipOptions={{position: 'bottom'}}
                                            onClick={() => {
                                              localStorage.setItem('scale', 'normal')
                                              document.getElementById('app').classList.add('scale-normal')
                                              document.getElementById('app').classList.remove('scale-small')
                                              window.App.forceUpdate();
                                            }}
                                    />
                                    }
                                    {localStorage.getItem('scale') === 'normal' &&
                                    <Button icon="pi pi-search-minus"
                                            type="button"
                                            tooltip={"Betűméret csökkentése"}
                                            tooltipOptions={{position: 'bottom'}}
                                            onClick={() => {
                                              localStorage.setItem('scale', 'small')
                                              document.getElementById('app').classList.remove('scale-normal')
                                              document.getElementById('app').classList.add('scale-small')
                                              window.App.forceUpdate();
                                            }}
                                    />
                                    }
                                    {!localStorage.getItem('scale') &&
                                    <Button icon="pi pi-search-minus"
                                            type="button"
                                            tooltip={"Betűméret csökkentése"}
                                            tooltipOptions={{position: 'bottom'}}
                                            onClick={() => {
                                              localStorage.setItem('scale', 'small')
                                              window.App.forceUpdate();
                                            }}
                                    />
                                    }
                                    {process.env.REACT_APP_BRAND === 'entryfish' &&
                                    <Button icon="pi pi-info-circle"
                                            type="button"
                                            tooltip={"Felhasználói kézikönyv"}
                                            tooltipOptions={{position: 'bottom'}}
                                            className={"p-button-highlighted"}
                                            onClick={() => {
                                              window.location.href = require('./assets/files/entryfish/entryfish_felhasznaloi_kezikonyv.pdf').default;
                                            }}
                                    />
                                    }
                                    {process.env.REACT_APP_BRAND === 't2d' &&
                                      <>
                                      <Button icon="pi pi-info-circle"
                                              type="button"
                                              tooltip={"Névjegy"}
                                              tooltipOptions={{position: 'bottom'}}
                                              className={"p-button"}
                                              onClick={() => {
                                                this.setState({
                                                  aboutDialog: true
                                                })
                                              }}
                                      />
                                      <Dialog visible={this.state.aboutDialog}
                                              style={{width: '750px'}}
                                              header="A szoftver névjegye"
                                              modal
                                              onHide={() => {
                                                this.setState({
                                                  aboutDialog: false
                                                })
                                              }}>
                                        <h2 className={"p-mb-0 p-pb-0"}>Szoftver megnevezése</h2>
                                        <strong>Trackie.io Ipar 4.0 Vállalatirányítási Rendszer</strong>
                                        <h2 className={"p-mb-0 p-pb-0"}>Kiadja és üzemelteti</h2>
                                        <strong>Trackie.io Kft. <br />székhely: 1025 Budapest, Ruthén út 28.<br />cégjegyzékszám: 01-09-377556,<br />adószám: 28963271-2-41</strong>
                                        <h2 className={"p-mb-0 p-pb-0"}>Licensz tulajdonos</h2>
                                        <strong>T.2.D. Kereskedelmi és Szolgáltató Bt. (7300 Komló, Kossuth Lajos u. 3.)</strong>
                                        <h2 className={"p-mb-0 p-pb-0"}>Licensz azonosító</h2>
                                        <strong>TRIOT2D2023004IP</strong>
                                      </Dialog>
                                      </>
                                    }
                                    {localStorage.getItem("userName") &&
                                    <>
                                      <Button className={'p-button-warning p-text-uppercase'}
                                              icon="pi pi-user"
                                              tooltip={"Profil"}
                                              tooltipOptions={{position: 'bottom'}}
                                              onClick={() => {
                                                window.App.setState({sidebarProfileUpdate: true})
                                              }}/>
                                      <Button icon="pi pi-fw pi-power-off"
                                              className={'p-button-danger'}
                                              tooltip={"Kijelentkezés"}
                                              tooltipOptions={{position: 'bottom'}}
                                              onClick={() => {
                                                if (window.confirm('A kilépés megerősítésére van szükség!')) {
                                                  UserLogoutService()
                                                }
                                              }}/>
                                    </>
                                    }
                                  </div>
                                </>
                              }
                            />
                          </>
                        </nav>
                      </div>
                    )}
                    <div id="page">
                      {process.env.REACT_APP_BRAND === 'lbuild' &&
                      <Route path="/" component={FinanceDashboardPage} exact/>
                      }
                      {process.env.REACT_APP_BRAND === 'entryfish' &&
                      <Route path="/" component={DashboardEntryfishPage} exact/>
                      }
                      {process.env.REACT_APP_BRAND === 'brw' &&
                      <Route path="/" component={ManufacturingDashboardPage} exact/>
                      }
                      {process.env.REACT_APP_BRAND === 'trackie' &&
                      <Route path="/" component={DashboardPage} exact/>
                      }
                      {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
                      <Route path="/subscriptions" component={SubscriptionPage} exact/>
                      }
                      {process.env.REACT_APP_REGISTRATION == 'enabled' &&
                      <Route path="/registration/" component={RegisterPage}/>
                      }
                      <Route path="/partner" component={PartnerDashboardPage}/>
                      <Route path="/settings" component={SettingsDashboardPage}/>
                      {process.env.REACT_APP_BRAND === 'entryfish' &&
                      <Route path="/report" component={ReportDashboardEntryfishPage}/>
                      }
                      {process.env.REACT_APP_BRAND !== 'entryfish' &&
                      <Route path="/report" component={ReportDashboardPage}/>
                      }
                      <Route path="/finance" component={FinanceDashboardPage}/>
                      <Route path="/project" component={ProjectDashboardPage}/>
                      <Route path="/entry" component={EntryDashboardPage}/>
                      {process.env.REACT_APP_BRAND === 'entryfish' &&
                      <Route path="/hr" component={_HrDashboardPage_Entryfish}/>
                      }
                      {process.env.REACT_APP_BRAND !== 'entryfish' &&
                      <Route path="/hr" component={HrDashboardPage}/>
                      }
                      <Route path="/safety" component={SafetyDashboardPage}/>
                      <Route path="/purchase" component={PurchaseDashboardPage}/>
                      <Route path="/order" component={OrderDashboardPage}/>
                      <Route path="/inventory" component={InventoryDashboardPage}/>
                      <Route path="/manufacturing" component={ManufacturingDashboardPage}/>
                      <Sidebar position="right" visible={window.App.state.sidebarProfileUpdate} closeOnEscape={true}
                               style={{width: '780px'}}
                               onHide={() => {
                                 window.App.setState({sidebarProfileUpdate: false})
                               }}>
                        <h2 className={"text-primary"}>Profil módosítása</h2>
                        <ProfileFormComponent/>
                      </Sidebar>
                      {process.env.REACT_APP_BRAND === 'entryfish' &&
                      <>
                        <Sidebar position="right" visible={window.App.state.sidebarEntrylocationByNetworkShow}
                                 closeOnEscape={true}
                                 style={{width: '780px'}}
                                 onHide={() => {
                                   window.App.setState({sidebarEntrylocationByNetworkShow: false})
                                 }}>
                          <h2 className={"text-primary"}>Projekt adatlap</h2>
                          <EntrylocationByNetworkShowComponent initialValues={{id: localStorage.getItem('networkId')}}/>
                        </Sidebar>
                        <Sidebar position="right" visible={window.App.state.sidebarEntrylocationByNetworkUpdate}
                                 closeOnEscape={true}
                                 style={{width: '780px'}}
                                 onHide={() => {
                                   window.App.setState({sidebarEntrylocationByNetworkUpdate: false})
                                 }}>
                          <h2 className={"text-primary"}>Projekt módosítása</h2>
                          <EntrylocationByNetworkFormComponent initialValues={{id: localStorage.getItem('networkId')}}/>
                        </Sidebar>
                      </>
                      }
                    </div>
                  </BlockUI>
                </>
              )}
            </>
          )}
        </div>
      </Router>
    );
  }
}

export default withTranslation('common')(App);

